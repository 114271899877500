
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return (
   <div className="App">
     <Router>
       <Routes>
       </Routes>
     </Router>
     <div class='center-image'>
          <img src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExbDlhOWM5dTVvbXltdTY3a2s4dDljM2VkeWF1bnowYXM1c3M0dXR6bCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Mah9dFWo1WZX0WM62Q/giphy.gif" width="480" height="480" title="0npm" class="giphy-embed" allowFullScreen></img>
     </div>
     </div>
  );
 }
function Home() {
  return <h1>Home</h1>;
 }
export default App;
